// components/LoginForm.tsx
'use client';

import { faLockOpen, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import { useRouter } from 'next/navigation';
import { useState, useTransition } from 'react';

export default function LoginForm() {
  const router = useRouter();
  const [secretKey, setSecretKey] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPending, startTransition] = useTransition();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitting(true);

    Cookies.remove('decryptionKey');
    Cookies.set('decryptionKey', secretKey);

    startTransition(() => {
      router.push('/browser/?svc=default');
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <div className="mt-2">
          <input
            type="password"
            id="secretKey"
            className="block w-full rounded-md border-0 bg-white/5 px-2 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
            value={secretKey}
            onChange={(event) => setSecretKey(event.target.value)}
            autoComplete="off"
          />
        </div>
      </div>

      <div>
        <button
          type="submit"
          className={`inline-flex w-full items-center justify-center gap-x-2 rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 ${
            isSubmitting || isPending || !secretKey
              ? 'cursor-not-allowed bg-gray-500'
              : 'bg-indigo-500 hover:bg-indigo-400'
          }`}
          disabled={isSubmitting || isPending || !secretKey}
        >
          {isSubmitting || isPending ? (
            <FontAwesomeIcon icon={faSpinner} className="h-5 w-5 animate-spin" aria-hidden="true" />
          ) : (
            <FontAwesomeIcon icon={faLockOpen} className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          )}
          <span>{isSubmitting || isPending ? 'Decrypting...' : 'Decrypt'}</span>
        </button>
      </div>
    </form>
  );
}
